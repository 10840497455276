<template>
    <div class="gk-card as-listing-item d-flex justify-content-start align-items-center p-4" v-if="emailTemplate">
        <div>
            <p class="mb-0 text-microcopy--60 font-weight-bold">{{ $t(emailTemplate.editor) }}</p>
            <router-link
                class="title"
                tag="a"
                :to="{ name: 'email-template', params: { id: emailTemplate.id } }">
                {{ emailTemplate.name }}
            </router-link>
            <p class="mb-0 text-muted" v-if="emailTemplate.description">{{ emailTemplate.description }}</p>
            <p class="mt-2 mb-0 text-muted text-microcopy--65">{{ $t('audit_created', { dateTime: dateLiteral(emailTemplate.meta.createdDate), actor: emailTemplate.meta.createdBy }) }}</p>
            <p class="mb-0 text-muted text-microcopy--65">{{ $t('audit_updated', { dateTime: dateLiteral(emailTemplate.meta.lastModifiedDate), actor: emailTemplate.meta.lastModifiedBy }) }}</p>
        </div>
    </div>
</template>

<script>
/**
 * EmailTemplate listing item for EmailTemplatesView.
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'EmailTemplateListingItem',
    components: {},
    props: {
        emailTemplate: {
            type: Object,
            default: null,
            required: true
        }
    }
};
</script>
